export const tipos = [
  {
    value : 'LINK_EXTERNO',
    desc  : 'Link Externo',
    aba   : 1,
    label : 'URL Destino'
  },
  {
    value : 'VIDEO_YOUTUBE',
    desc  : 'Vídeo Youtube',
    aba   : 0,
    label : 'Código do vídeo'
  }
]