<template>
    <modal name="canalEdit" height="auto" width="900" :lazy="true" :scrollable="true" :clickToClose="false">
        <CloseModal :close="close" />
        <div class="modalbox">
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab :title="modalTitle">
                        <div class="form">
                            <div class="alert remover" v-show="!!errorMessage">
                                <a href="#" class="close" @click="errorMessage = null">&times;</a>
                                Falha ao Salvar alterações:<br>
                                <p>{{ errorMessage }}</p>
                            </div>
                            <div class="xrow">
                                <div class="col-1-3">
                                    <div class="form-group">
                                        <image-picker
                                                label="Imagem (Recomendado 82 x 82 px)"
                                                placeholder="Colar URL ou clique ao lado para upload"
                                                v-model="item.imagem" />
                                        <img v-if="item.imagem" :src="item.imagem" alt="Preview imagem" class="thumbnail thumbnail-small"/>
                                        <div class="message">{{ validation.firstError('item.imagem') }}</div>
                                    </div>
                                </div>
                                <div class="col-2-3">
                                    <div class="form-group">
                                        <label>Descrição</label>
                                        <input class="form-control" v-model="item.desc" maxlength="255"/>
                                        <div class="message">{{ validation.firstError('item.desc') }}</div>
                                    </div>

                                    <div class="form-group">
                                        <label>Url (link)</label>
                                        <input class="form-control" v-model="item.url" maxlength="2000"/>
                                        <div class="message">{{ validation.firstError('item.url') }}</div>
                                    </div>

                                    <div class="xrow">
                                        <div class="col-1-2">
                                            <div class="form-group">
                                                <label>Ordem de exibição</label>
                                                <input class="form-control" v-model="item.ordem" maxlength="2"/>
                                            </div>
                                        </div>
                                        <div class="col-1-2">
                                            <div class="form-group">
                                                <label>Abrir em nova aba</label>
                                                <select class="form-control" v-model="item.novaAba">
                                                    <option value="0">Não</option>
                                                    <option value="1">Sim</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CTab>
                </CTabs>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit()">
                Salvar Alterações
            </button>
            <button type="button" class="button" v-on:click="close">Fechar</button>
        </div>
    </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import ImagePicker from "../../components/ImagePicker";
import { tipos } from "./helpers/constants";
import CloseModal from "../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "Campo de preenchimento obrigatório",
  },
});

export default {
  name: "canalEdit",
  components: {
    ImagePicker,
    CloseModal,
  },
  props: ["onClose", "onSubmit", "item", "close"],
  data() {
    return {
      tipos: tipos,
      errorMessage: null,
    };
  },
  validators: {
    "item.desc": (value) => Validator.value(value).required(),
    "item.ordem": (value) => Validator.value(value).required(),
    "item.novaAba": (value) => Validator.value(value).required(),
    "item.url": (value) => Validator.value(value).required(),
    "item.imagem": (value) => Validator.value(value).required(),
  },
  computed: {
    modalTitle() {
      if (this.item.id) {
        return "Editar Canal";
      }
      return "Criar Canal";
    },
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.onSubmit(this.item).catch((error) => {
            this.errorMessage = error.message;
          });
        }
      });
    },
  },
};
</script>
