<template>
    <div>
        <Loading :isActive="loading"></Loading>
        <Edit
            :item="currentItem"
            :close="closeEdit"
            :onSubmit="onSubmit"
        />
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Canais">
                <div class="form-group" style="margin-bottom: 10px; overflow: hidden">
                    <button v-on:click="openCreate" class="button button-success pull-right">
                        <fa-icon icon="plus" />
                        Criar
                    </button>
                </div>
                <div class="table-responsive">
                    <datatable :columns="table.columns" :data="items" class="table table-hover">
                        <template slot-scope="{ row }">
                            <tr>
                                <td>{{ row.id }}</td>
                                <td><img v-if="row.imagem" :src="row.imagem" class="thumbnail thumbnail-small"></td>
                                <td>{{ row.desc }}</td>
                                <td>{{ row.ordem }}</td>
                                <td>{{ row.novaAba | boolean }}</td>
                                <td class="actions">
                                    <button type="button" class="button button-primary" v-on:click="openEdit(row)">
                                        <fa-icon icon="pencil-alt" />
                                    </button>
                                    <button type="button" name="button" v-on:click="deleteConfirm(row.id)" class="button button-error">
                                        <fa-icon icon="trash-alt" />
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </datatable>
                </div>
            </CTab>
        </CTabs>
    </div>
</template>

<script>
import clone from 'just-clone';
import TemplateModulo from '../../components/TemplateModulo';
import { get, post, put, del } from '@/helpers/apiRequest';
import Edit from './Edit';

const initialItem = {
  id: null,
  desc: '',
  imagem: '',
  url: '',
  novaAba: 0,
  ordem: '1',
};

export default {
  name: 'canais-atendimento',
  components: {
    Edit,
    TemplateModulo,
  },
  data() {
    return {
      currentItem: {},
      loading: false,
      error: false,
      items: [],
      term: '',
      table: {
        columns: [
          { label: 'ID', field: 'id', align: 'center', sortable: false },
          { label: 'Imagem', field: 'imagem', sortable: false },
          { label: 'Descrição', field: 'desc', sortable: false },
          { label: 'Ordem', field: 'ordem', sortable: false },
          { label: 'Nova Aba', field: 'novaAba', sortable: false },
          { label: '', field: '', sortable: false },
        ],
      },
    };
  },
  methods: {
    loadCanais(page) {
      this.loading = true;
      get(`/admin/canais-atendimento`)
        .then((json) => {
          this.items = json;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.currentItem.novaAba = +item.novaAba;
      this.$modal.show('canalEdit');
    },
    openCreate() {
      this.currentItem = clone(initialItem);
      this.$modal.show('canalEdit');
    },
    closeEdit() {
      this.currentItem = clone(initialItem);
      this.$modal.hide('canalEdit');
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/canais-atendimento/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadCanais();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    onSubmit(item) {
      this.loading = true;
      const request = this.request(item);
      request
        .then(() => {
          this.closeEdit();
          this.loadCanais();
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
      return request;
    },
    request(item) {
      if (item.id) {
        return put(`/admin/canais-atendimento/${item.id}`, item);
      }
      return post(`/admin/canais-atendimento`, item);
    },
  },
  beforeMount() {
    this.loadCanais();
  },
};
</script>
